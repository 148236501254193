import PropTypes from 'prop-types';

import Metrics from '@spotahome/soyuz-tracking';
import { trans } from '@spotahome/soyuz-trans/client';

import Button from '../../Button';
import Modal from '../../Modal';
import ModalHeader from '../../ModalHeader';

import styles from './SpottyCatVPModal.module.scss';
import SoyuzAnalytics from "@spotahome/soyuz-analytics";

const SPOTTY_CAT_IMG =
  'https://assets.spotahome.com/static/spotty-monocle-rebranding.gif';

const SpottyCatVPModal = ({ isOpen, onClose, onNextStep }) => {
  const handleValuePropClose = () => {
    onClose();
    Metrics.ga.sendEvent('Spottycat', 'OpenCat', 'close-click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {action: 'OpenCat', value: 'close-click'});
  };

  return (
    <Modal
      contentLabel="modal"
      size="small"
      isOpen={isOpen}
      onRequestClose={handleValuePropClose}
      cornerPosition="bottomRight"
      className={styles['spotty-cat-vp-modal']}
      isRounded={false}
    >
      <ModalHeader onRequestClose={handleValuePropClose} />

      <span className={styles['spotty-cat-vp-modal__title']}>
        {trans('spottycat.valueprop.title')}
      </span>

      <p className={styles['spotty-cat-vp-modal__description']}>
        {trans('spottycat.valueprop.description')}
      </p>

      <img
        className={styles['spotty-cat-vp-modal__image']}
        src={SPOTTY_CAT_IMG}
        alt="spotty cat monocle"
      />

      <Button
        color="interaction"
        className={styles['spotty-cat-vp-modal__button']}
        onClick={onNextStep}
      >
        {trans('spottycat.valueprop.button')}
      </Button>
    </Modal>
  );
};

SpottyCatVPModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired
};

export default SpottyCatVPModal;
